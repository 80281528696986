// ** Initial State
const initialState = {
  data: [],
  total: 1,
  params: {},
  selectedUser: null,
  popup: {
    data: [],
    view: {},
    total: 0,
    params: {}
  },
  rowData: {
    data: []
  },
  dataDefault: [],
  open_modal: false,
  data_modal: []
}

const setting = (state = initialState, action) => {
  switch (action.type) {
    case 'SMS_SET_GET_DATA':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'POPUP_SET_GET_DATA':
      return {
        ...state,
        popup: {
          ...state.popup,
          data: action.data,
          total: action.totalPages,
          params: action.params
        }
      }
    case 'POPUP_ID_GET_DATA':
      return {
        ...state,
        popup: {
          ...state.popup,
          view: action.data[0]
        }
      }
    case 'CONTACT_SET_GET_DATA':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'ROLE_SET_GET_DATA':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'ROLE_EDIT_GET_DATA':
      return {
        ...state,
        rowData: {
          data: action.data
        }
      }
    case 'ROLE_DEFAULT_GET_DATA':
      return {
        ...state,
        dataDefault: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'WHEEL_OPEN_MODAL':
      return {
        ...state,
        open_modal: action.open_modal
      }
    case 'WHEEL_OPEN_EDIT_MODAL':
      return {
        ...state,
        data_modal: action.data
      }
    case 'WHEEL_REWARD_GET_DATA':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    default:
      return { ...state }
  }
}

export { setting }
